<template>
  <b-modal
    id="modal-xl-export"
    ok-title="Save"
    centered
    size="xl"
  >
    <div class="container-ctrl">
      <b class="h1">Goods Delivery Note</b>
      <!-- <p>Please choose the right category for your product.</p> -->
      <hr />
      <b-row>
        <b-col md="6">
          <b-form-group label="SKU:" label-cols-md="3">
            <validation-provider
              #default="{ errors }"
              name="Product name"
              rules="required"
            >
              <b-form-input
                :state="errors.length > 0 ? false : null"
                placeholder="SKU"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Auto generated Code:" label-cols-md="3">
            <validation-provider
              #default="{ errors }"
              name="Product name"
              rules="required"
            >
              <b-form-input
                :state="errors.length > 0 ? false : null"
                placeholder="Product name"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Product name:" label-cols-md="3">
            <validation-provider
              #default="{ errors }"
              name="Product name"
              rules="required"
            >
              <b-form-input
                :state="errors.length > 0 ? false : null"
                placeholder="Product name"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Reciept date:" label-cols-md="3">
            <validation-provider
              #default="{ errors }"
              name="Product name"
              rules="required"
            >
              <b-form-input
                :state="errors.length > 0 ? false : null"
                placeholder="Reciept date"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Supplier:" label-cols-md="3">
            <validation-provider
              #default="{ errors }"
              name="Product name"
              rules="required"
            >
              <b-form-input
                :state="errors.length > 0 ? false : null"
                placeholder="Supplier"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Note:" label-cols-md="3">
            <b-form-textarea
              rows="6"
              max-rows="6"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-table-simple
            hover
            caption-top
            responsive
          >
            <b-thead>
              <b-tr>
                <b-th>Variations</b-th>
                <b-th>SKU</b-th>
                <b-th>Stock</b-th>
                <b-th>Selling price</b-th>
                <b-th>Quantity</b-th>
                <b-th>Unit price</b-th>
                <b-th>Total</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th>Red Color - XL</b-th>
                <b-th>AKU29032021</b-th>
                <b-th>2</b-th>
                <b-th>$25</b-th>
                <b-th class="w--145">
                  <b-input-group
                    prepend="-"
                    class="input-group-merge"
                  >
                    <b-form-input
                      type="number"
                      min="0"
                      number
                    >
                      $
                    </b-form-input>
                  </b-input-group>
                </b-th>
                <b-th class="w--145">
                  <b-input-group
                    prepend="$"
                    class="input-group-merge"
                  >
                    <b-form-input
                      type="number"
                      min="0"
                      number
                    >
                      $
                    </b-form-input>
                  </b-input-group>
                </b-th>
                <b-th>52</b-th>
              </b-tr>
              <b-tr>
                <b-th>Red Color - XL</b-th>
                <b-th>AKU29032021</b-th>
                <b-th>2</b-th>
                <b-th>$25</b-th>
                <b-th class="w--145">
                  <b-input-group
                    prepend="-"
                    class="input-group-merge"
                  >
                    <b-form-input
                      type="number"
                      min="0"
                      number
                    >
                      $
                    </b-form-input>
                  </b-input-group>
                </b-th>
                <b-th class="w--145">
                  <b-input-group
                    prepend="$"
                    class="input-group-merge"
                  >
                    <b-form-input
                      type="number"
                      min="0"
                      number
                    >
                      $
                    </b-form-input>
                  </b-input-group>
                </b-th>
                <b-th>52</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BTr,
  BTableSimple,
  BTbody,
  BThead,
  BTh,
  BInputGroup,
  BFormTextarea,
  // BInputGroupPrepend,
  // BButton,
  // BInputGroupAppend,
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Request from '@/plugins/service/index'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTr,
    BTableSimple,
    BTbody,
    BThead,
    BTh,
    BInputGroup,
    BFormTextarea,
    // BInputGroupPrepend,
    // BButton,
    // BInputGroupAppend,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      number: 0,
    }
  },
  mounted() {
  },
  methods: {
    valueChange(newValue) {
      if (newValue <= 0) {
        this.$emit('input', 0)
      } else {
        this.$emit('input', newValue)
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.w--145
  width: 145px
</style>
