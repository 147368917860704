var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-xl-export","ok-title":"Save","centered":"","size":"xl"}},[_c('div',{staticClass:"container-ctrl"},[_c('b',{staticClass:"h1"},[_vm._v("Goods Delivery Note")]),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"SKU:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Product name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"SKU","readonly":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Auto generated Code:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Product name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Product name","readonly":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Product name:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Product name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Product name","readonly":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Reciept date:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Product name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Reciept date","readonly":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Supplier:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Product name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Supplier","readonly":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Note:","label-cols-md":"3"}},[_c('b-form-textarea',{attrs:{"rows":"6","max-rows":"6","no-resize":""}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-table-simple',{attrs:{"hover":"","caption-top":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Variations")]),_c('b-th',[_vm._v("SKU")]),_c('b-th',[_vm._v("Stock")]),_c('b-th',[_vm._v("Selling price")]),_c('b-th',[_vm._v("Quantity")]),_c('b-th',[_vm._v("Unit price")]),_c('b-th',[_vm._v("Total")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-th',[_vm._v("Red Color - XL")]),_c('b-th',[_vm._v("AKU29032021")]),_c('b-th',[_vm._v("2")]),_c('b-th',[_vm._v("$25")]),_c('b-th',{staticClass:"w--145"},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"-"}},[_c('b-form-input',{attrs:{"type":"number","min":"0","number":""}},[_vm._v(" $ ")])],1)],1),_c('b-th',{staticClass:"w--145"},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"type":"number","min":"0","number":""}},[_vm._v(" $ ")])],1)],1),_c('b-th',[_vm._v("52")])],1),_c('b-tr',[_c('b-th',[_vm._v("Red Color - XL")]),_c('b-th',[_vm._v("AKU29032021")]),_c('b-th',[_vm._v("2")]),_c('b-th',[_vm._v("$25")]),_c('b-th',{staticClass:"w--145"},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"-"}},[_c('b-form-input',{attrs:{"type":"number","min":"0","number":""}},[_vm._v(" $ ")])],1)],1),_c('b-th',{staticClass:"w--145"},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"type":"number","min":"0","number":""}},[_vm._v(" $ ")])],1)],1),_c('b-th',[_vm._v("52")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }