<template>
  <!-- eslint-disable vue/html-indent -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-row>
    <b-col sm="9">
      <b-card>
        <b-card-header><b class="h3">Product Detail</b></b-card-header>
        <b-card-body>
          <b-row>
            <b-col sm="7">
              <div class="d-flex box mb-2">
                <div class="img-important mr-2">
                  <b-img :src="model.avatar" />
                </div>
                <div>
                  <p>{{ model.name }}</p>
                  <p>{{ model.sku }}</p>
                  <strong>{{
                    model.short_content && model.short_content !== null
                      ? model.short_content
                      : model.content
                  }}</strong>
                </div>
              </div>
            </b-col>
            <b-col sm="5">
              <p><strong>Category: </strong>{{ model.category_name }}</p>
              <p><strong>Supplier: </strong>{{ model.supplier_name }}</p>
            </b-col>
          </b-row>
          <b-table
            :items="items"
            :fields="fields"
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #cell(avatar)="item">
              <b-avatar :src="item.item.avatar" />
            </template>
            <template #cell(variation)="item">
              <span>
                {{ item.item.name_att1 }}
              </span>
              <span v-if="item.item.name_att2 !== null">
                - {{ item.item.name_att2 }}
              </span>
              <span v-if="item.item.name_att3 !== null">
                - {{ item.item.name_att3 }}
              </span>
            </template>

            <template #cell(is_active)="item">
              <ActiveStatus :value="item.item.is_active" />
            </template>
          </b-table>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">
                  Per page
                </label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="10" sm="8" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="3">
      <div class="d-flex mb-2">
        <b-button
          v-b-modal.modal-xl-export
          class="mr-1 w-100"
          variant="outline-secondary"
        >
          Export goods
        </b-button>
        <popup-export />
        <b-button v-b-modal.modal-xl class="w-100" variant="primary">
          Receive goods
        </b-button>
        <popup-receive :data="model" :list="items" />
      </div>
      <b-card>
        <h3>History</h3>
        <div
          v-for="(item, index) in history"
          :key="index"
          class="d-flex space-between"
        >
          <div>
            <div class="d-flex">
              <strong>{{ item.title }}</strong>
              <i class="mb-0 ml-2">
                <small>{{ item.created_at }}</small>
              </i>
            </div>
            <p class="text-primary">{{ item.id }}</p>
          </div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            size="sm"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </div>
        <div v-if="!history || history.length == []">
          No History for this product
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BImg,
  BButton,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import ActiveStatus from '@/views/components/active-status/index.vue'
import PopupReceive from '@/views/pages/warehouse/components/PopupReceive.vue'
import PopupExport from '@/views/pages/warehouse/components/PopupExport.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BImg,
    BButton,
    BTable,
    ActiveStatus,
    PopupReceive,
    PopupExport,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      fields: [
        { key: 'avatar', label: 'Avatar' },
        { key: 'sku', label: 'SKU' },
        { key: 'variation', label: 'Variation' },
        { key: 'price', label: 'Selling price', sortable: true },
        { key: 'stock', label: 'stock', sortable: true },
        { key: 'sold', label: 'Sold', sortable: true },
        { key: 'is_active', label: 'Is active' },
        { key: 'updated_at', label: 'Lasted update' },
      ],
      model: {},
      items: [],
      history: [
        {
          id: 'R-20210329-1',
          title: 'Receive goods',
          created_at: '22/02/2021 16:45',
        },
        {
          id: 'R-20210329-2',
          title: 'Receive goods',
          created_at: '22/02/2021 16:46',
        },
      ],
    }
  },
  mounted() {
    this.loadDetail()
    this.loadList()
  },
  methods: {
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_warehouses?template_id=${templateId}&product_id=${this.$route.query.product_id}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = res.data.data.items
            // this.perPage = res.data.data.count_page
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.items.length
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadDetail() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_product/${this.$route.query.product_id}?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = await {
              ...res.data.data,
              supplier_name: null,
              category_name: '',
              code: this.generateCode('R', 1, 5),
              status: 1,
              unit_price: null,
              total_price: null,
              reciept_date: '2021/04/12 12:03:05',
            }
            const resSupplier = await Request.get(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_supplier/${res.data.data.supplier_id}?template_id=${templateId}&limit=1000`,
            )
            if (resSupplier.status === 200) {
              if (resSupplier.data.status) {
                this.model.supplier_name = resSupplier.data.data.name
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(resSupplier.data.error, ',')),
                  },
                })
              }
            }
            this.getCategoryProduct(res.data.data.category_id)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async getCategoryProduct(categoryID) {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      const categoryIDSplit = categoryID.split(',')
      const resCategory1 = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/platform_category/${categoryIDSplit[0]}?template_id=${templateId}&limit=1000`,
      )
      if (resCategory1.status === 200) {
        if (resCategory1.data.status) {
          this.model.category_name = `${resCategory1.data.data.name}`
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Login fail',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(resCategory1.data.error, ',')),
            },
          })
        }
      }
      if (categoryIDSplit[1] && categoryIDSplit[1] !== undefined) {
        const resCategory2 = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_category/${categoryIDSplit[1]}?template_id=${templateId}&limit=1000`,
        )
        if (resCategory2.status === 200) {
          if (resCategory2.data.status) {
            this.model.category_name += ` > ${resCategory2.data.data.name}`
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(resCategory2.data.error, ',')),
              },
            })
          }
        }
      }
      if (categoryIDSplit[2] && categoryIDSplit[2] !== undefined) {
        const resCategory3 = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_category/${categoryIDSplit[2]}?template_id=${templateId}&limit=1000`,
        )
        if (resCategory3.status === 200) {
          if (resCategory3.data.status) {
            this.model.category_name += ` > ${resCategory3.data.data.name}`
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(resCategory3.data.error, ',')),
              },
            })
          }
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.box
  align-items: center
.img-important
  width: 100px

  img
    width: 100%
.space-between
  justify-content: space-between
  align-items: center
  margin-bottom: 14px
  &:not(:last-child)
    border-bottom: 1px solid #ccc
</style>
